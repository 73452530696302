<template>
  <div style="width: 1200px; margin: 10px auto">
    错误信息:<el-input
      placeholder="请输入错误信息"
      v-model="message"
      clearable
      @change="getList"
      style="width: 200px; margin: 20px"
      size="mini"
    >
    </el-input>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="created" label="日期" width="180">
      </el-table-column>
      <el-table-column prop="message" label="错误信息"> </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="total"
      :page-size="20"
      @current-change="currentPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getErrorMsg } from "@/api/design";
export default {
  data() {
    return {
      tableData: [],
      message: "",
      pageSize: 20,
      pageNumber: 1,
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    currentPage(val) {
      this.pageNumber = val;
      this.getList();
    },
    async getList() {
      let res = await getErrorMsg({
        message: this.message,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      });
      this.tableData = res.data.list;
      this.total = res.data.total;
      console.log(res);
    },
  },
};
</script>

<style>
</style>